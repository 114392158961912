import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  ctr: any = 0;

  constructor(private loaderService: LoaderService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
    this.loaderService.progress.next(100);
  }

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   this.loaderService.isLoading.next(true);

  //   return next.handle(req).pipe(
  //     tap(
  //       (event: HttpEvent<any>) => {
  //         console.log('Event Intercept', event);
  //         if (event.type === HttpEventType.DownloadProgress) {
  //           // here we get the updated progress values, call your service or what ever here
  //           this.removeRequest(req);
  //           this.loaderService.progress.next(Math.round(event.loaded)); // display & update progress bar
  //         } else if (event.type === HttpEventType.Response) {
  //           this.removeRequest(req);
  //           this.loaderService.progress.next(100); // hide progress bar
  //         }
  //       },
  //       (error) => {
  //         this.removeRequest(req);
  //         this.loaderService.progress.next(100); // hide progress bar
  //       }
  //     )
  //   );
  // }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.loaderService.isLoading.next(true);

    return Observable.create(
      (observer: {
        next: (arg0: HttpResponse<any>) => void;
        error: (arg0: any) => void;
        complete: () => void;
      }) => {
        const subscription = next.handle(req).subscribe(
          (event) => {
            if (this.ctr < 90) this.loaderService.progress.next(this.ctr++);

            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          (err) => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          }
        );
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      }
    );
  }
}
