import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { EmptyStateComponent } from './empty-state.component';

// import { NzConfigService } from 'ng-zorro-antd/core/config';

@Component({
  standalone: true,
  imports: [
    EmptyStateComponent,
    CommonModule
  ],
  selector: 'empty-state-global',
  template: `
    <ng-template #customTpl>
      <empty-state></empty-state>
    </ng-template>
  `,
})
export class EmptyStateGlobalComponent {

  @ViewChild('customTpl', { static: true })
  customTpl!: TemplateRef<string>;
}
