import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "projects/aloe2-library/src/lib/store";
import { TENANT_STATE } from "projects/aloe2-main/src/data/models/tenant.model";
import { map, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class BatchAiGuard  {
  
    router: Router = inject(Router);
    store: Store = inject(Store);
  
    canActivate() {
        return this.store.select(TENANT_STATE).pipe(
            map((tenant: any) => tenant?.info?.enable_batch_ai === 'Yes'),
            tap(res => {
                if(!res) {
                    this.router.navigateByUrl('/');
                }
            })
        )
    }

  }
  