
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthProvider } from 'projects/aloe2-main/src/common/auth.provider';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicTaskFormGuard  {

  constructor(private _auth: AuthProvider,private _router: Router) {}

  canActivate(): Observable<boolean> {
    return this._auth.token().pipe( 
      map(token => {
        if(token){
          this._router.navigateByUrl('/task/form');
          return false;
        }
        return true;
      })
    );
  }
}
