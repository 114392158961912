import {
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { IconsProviderModule } from './icons-provider.module';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { ALOE_CONFIG } from 'projects/aloe2-library/src/lib/config/aloe.config';
import { SanitizeHtmlPipe } from '../../../aloe2-library/src/lib/pipes/sanitize-html.pipe';
import { AppCommonModule } from '../common/common.module';
import { AloeDatePipe } from '../common/date.pipe';
import { RouterExtService } from '../common/router-ext.service';
import { environment } from '../environments/environment';
import { ReferenceLookupService } from './modules/task/components/dynamic/services/reference-control.service';
import { LoaderInterceptorService } from './shared/components/loader/interceptors/loader-interceptor.service';
import { LoaderService } from './shared/components/loader/services/loader.service';
import { EmptyStateGlobalComponent } from './shared/errors/empty-state/empty-state-global.component';
import { MessageService } from './shared/services/message.service';
import { SharedModule } from './shared/shared.module';
// import { LoaderService } from '.shared/components/loader/services/loader.service';
// import { LoaderInterceptorService } from './loader/interceptors/loader-interceptor.service';

const nzConfigFactory = (
): NzConfig => {
  return {
    empty: {
      nzDefaultEmptyContent: EmptyStateGlobalComponent,
    },
    pageHeader: {
      nzGhost: false
    }
  };
};
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppCommonModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    DatePipe,
    AloeDatePipe,
    SanitizeHtmlPipe,
    CurrencyPipe,
    MessageService,
    ReferenceLookupService,
    {
      provide: ALOE_CONFIG,
      useValue: {
        environment: environment,
      },
      multi: false,
    },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: NZ_CONFIG,
      useFactory: nzConfigFactory,
      deps: [],
    },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    RouterExtService
  ],
})
export class AppModule {
  constructor() {}
}
